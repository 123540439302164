import React from "react"
import overview from "../../assets/images/banners/new-images/about-page/overview.png"
import mission from "../../assets/images/banners/new-images/about-page/mission.png"
import values from "../../assets/images/banners/new-images/about-page/values.png"
import approach from "../../assets/images/banners/new-images/about-page/approach.png"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

export const AboutUs = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div className="about-banner-wrap banner-space about-us-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">About Aamanto</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--===========  our history wrapper  Start =============--> */}
        <div className="our-history-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h1 className="heading mb-20">
                    We create dreams<span className="text-color-primary"> for the future.</span> We realize them sucessfully.
                  </h1>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            <div className="timeline-area">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ht-timeline style-01">
                    <ul className="tm-timeline-list ht-animation-queue">
                      <li className="line"></li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              {/* <div className="date-wrap"><h2 className="year">Overview</h2></div> */}

                              <div className="photo">
                                <img
                                  src={overview}
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner" style={{ paddingTop: "0" }}>
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading text-primary">Company Overview</h6>
                                  <h5 className="">We are experts in what we do!</h5>

                                  <div className="text">
                                    Aamanto Technologies Inc. is the most
                                    trusted name in IT consulting and software
                                    development services. We deliver years of
                                    domain expertise and unrivaled value to our
                                    clients. Our solutions power clients'
                                    operations across diverse industries such as
                                    finance, government, healthcare, education,
                                    transportation, retailing, telecommunication
                                    and energy. We are a one-stop solution for
                                    your IT needs, including mobile app
                                    development, responsive web application
                                    development, cloud adoption, cybersecurity,
                                    digital transformation and data analytics.
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              {/* <div className="date-wrap"><h6 className="year"> Mission </h6></div> */}

                              <div className="photo">
                                <img
                                  src={mission}
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner" style={{ paddingTop: "0" }}>
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading text-primary">Our Mission & Vision</h6>
                                  <h5 className="">We visualize the ideas!</h5>

                                  {/* <h5 className="text mt-10 pb-2">Integrity</h5> */}
                                  <div className="text pb-2">
                                    <span className="fw-bold text-black">Integrity: </span>
                                    We stand behind our solutions, ensuring
                                    customer satisfaction is guaranteed. We have
                                    a set of defined methodologies based on the
                                    project requirements, planning, development
                                    and delivery timelines.
                                  </div>

                                  {/* <h5 className="text mt-10 pb-2">Candor</h5> */}
                                  <div className="text pb-2">
                                    <span className="fw-bold text-black">Candor: </span>

                                    We emphasize honesty in all interactions
                                    with our clients and vendors. During all
                                    project stages, we provide continuous
                                    communication to all parties involved.
                                    Providing communication to stakeholders,
                                    program team and project team.
                                  </div>
                                  {/* <h5 className="text mt-10 pb-2">Innovation</h5> */}
                                  <div className="text pb-2">
                                    <span className="fw-bold text-black">Innovation: </span>
                                    We aim to stay ahead of the curve of
                                    technology. We strive to provide innovative
                                    solutions using cutting-edge technologies,
                                    never resting on our laurels, keeping the
                                    risks and cost managed.
                                  </div>

                                  {/* <h5 className="text mt-10 pb-2">Growth</h5> */}
                                  <div className="text">
                                    <span className="fw-bold text-black">Growth: </span>
                                    Success is a journey, not a destination. We
                                    believe in continuous improvement and
                                    continuous delivery to our clients. We treat
                                    all issues as opportunities to grow and
                                    improve.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              {/* <div className="date-wrap"><h6 className="year"> Values </h6></div> */}

                              <div className="photo">
                                <img
                                  src={values}
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner" style={{ paddingTop: "0" }}>
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading text-primary">Our Values</h6>
                                  <h5 className="">Making a real difference in IT world!</h5>

                                  <div className="text">
                                    Aamanto Technology thinks that a diverse
                                    range of perspectives leads to new ideas. We
                                    want to foster an environment that allows
                                    our people to be themselves and express
                                    their unique experiences and ideas. Our
                                    commitment to diversity promotes a feeling
                                    of connection that distinguishes our
                                    culture. Our employees, customers, vendors,
                                    business partners, and visitors are treated
                                    with respect and are not discriminated
                                    against based on who they are, according to
                                    our global diversification, inclusiveness,
                                    and belonging approach. We adopt new
                                    technology and evolve services.
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item animate">
                        <div className="dots">
                          <div className="middle-dot"></div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 timeline-col left timeline-feature">
                            <div className="inner">
                              {/* <div className="date-wrap"><h6 className="year"> Approach </h6></div> */}

                              <div className="photo">
                                <img
                                  src={approach}
                                  alt="timeline-image-01"
                                  className="img-fluid bright-50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 timeline-col right timeline-info">
                            <div className="inner" style={{ paddingTop: "0" }}>
                              <div className="content-wrap">
                                <div className="content-body">
                                  <h6 className="heading text-primary">Our Approach</h6>
                                  <h5 className="">We Empower Our Customers!</h5>

                                  <div className="text">
                                    We are passionate about empowering our
                                    clients to expand their businesses, improve
                                    their productivity, and deliver the future
                                    at Aamanto Technologies. We achieve this
                                    through our technological competence in the
                                    world's most advanced technology. All of
                                    Aamanto's actions are directed by our
                                    client's goals and expectations. We strive
                                    for ethics and integrity in everything we
                                    do. As a result, every project starts with a
                                    forum to decide expectations. We go to
                                    extraordinary lengths to learn about our
                                    client's businesses, important areas, and
                                    needs. Regular interactions and feedback are
                                    an important component of our strategy for
                                    developing world-class solutions.
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AboutUs
